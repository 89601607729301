import NextImage from 'next/image';
import { SanityImageAsset, SanityReference } from '@fagmobler/sanity';
import { SanityClient } from '@sanity/client';
import { configuredSanityClient } from '@/lib/sanity/sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type ImageProps = {
  type?: 'cover' | 'contain';
  slot?: string;
  module: {
    _type: 'image';
    _key: string;
    asset: {
      _ref: string;
      _type: 'reference';
    };
    crop?: {
      bottom: number;
      left: number;
      right: number;
      top: number;
    };
    hotspot?: {
      height: number;
      width: number;
      x: number;
      y: number;
    };
  };
};

const getSanityImageUrl = (
  image: {
    _type?: 'image';
    asset?: SanityReference<SanityImageAsset>;
  },
  client: SanityClient = configuredSanityClient
) => {
  if (image._type === 'image' && image.asset?._ref) {
    const urlBuilder = imageUrlBuilder(client);

    return urlBuilder.image(image).url();
  }

  return '';
};

export const Image = ({ module, type, slot }: ImageProps) => {
  return (
    <div
      className={twMerge(
        clsx('relative size-full', {
          'overlay-after': slot === 'background',
        })
      )}
    >
      <NextImage
        src={getSanityImageUrl(module)}
        alt=""
        fill
        className={twMerge(
          clsx({
            'object-cover': type === 'cover',
            'object-contain object-center': type !== 'cover',
            'object-left-bottom': type !== 'cover' && slot === 'bottomLeft',
            'object-right-bottom': type !== 'cover' && slot === 'bottomRight',
            'object-left-top': type !== 'cover' && slot === 'topLeft',
            'object-right-top': type !== 'cover' && slot === 'topRight',
          })
        )}
      />
    </div>
  );
};
