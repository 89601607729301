import { FC, MouseEvent, useEffect, useState } from 'react';
import { AutocompleteSourceName } from '../components/SearchInput';
import { MagnifyingGlassIcon, TrashIcon } from '@heroicons/react/24/outline';
import Highlight from '../utils/Highlight';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type GenericTextItemProps = {
  id: string;
  sourceId: string;
  item: any;
  prop: string;
  highlight: boolean;
  icon?: string;
  onSelect: (query: string) => void;
  onRemove: (id: string, sourceId: string) => void;
  subtitleProp?: string;
};
export default function GenericTextItem({
  id,
  sourceId,
  item,
  prop,
  highlight,
  icon = 'search',
  onSelect,
  onRemove,
  subtitleProp,
}: GenericTextItemProps) {
  const [showRemove, setShowRemove] = useState<boolean>(false);

  useEffect(() => {
    setShowRemove(sourceId === `${AutocompleteSourceName.RECENT_SEARCHES}`);
  }, [sourceId]);

  const handleRemove = (e: MouseEvent): void => {
    e.stopPropagation();
    onRemove(id, sourceId);
  };

  return (
    <button
      className={twMerge(
        clsx(
          'flex gap-4 w-full items-center text-left hover:bg-neutral-30 rounded lg:p-2'
        )
      )}
      onClick={(): void => onSelect(item)}
    >
      <Image
        src={`/gfx/icons/${icon}.svg`}
        className={twMerge(
          clsx('w-4 h-4', {
            'my-1 self-start': subtitleProp,
          })
        )}
        width={16}
        height={16}
        unoptimized={true}
        alt="Search Item"
      />
      <span className="flex-grow flex flex-col">
        {highlight ? (
          <Highlight hit={item} attribute={prop} />
        ) : (
          <span className="body-sm text-neutral-80">{item[prop]}</span>
        )}
        {subtitleProp && (
          <span className="body-xs text-neutral-70 whitespace-pre-wrap">
            {item[subtitleProp]}
          </span>
        )}
      </span>

      <div>
        {showRemove && (
          <button type="button" onClick={handleRemove}>
            <Image
              src="/gfx/icons/trash.svg"
              className="w-4 h-4"
              width={16}
              height={16}
              alt="Search Item"
            />
          </button>
        )}
      </div>
    </button>
  );
}
