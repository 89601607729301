import { useInstantSearch } from 'react-instantsearch';
import SearchHeading from '../components/SearchHeading';
import CategoryItem from '../items/CategoryItem';

const CategoryResults = () => {
  const { results } = useInstantSearch();

  if (results.hits.length === 0) {
    return <></>;
  }

  return (
    <section className="flex flex-row items-center py-3 gap-1 flex-wrap">
      {results.hits.map((hit, index) => (
        <CategoryItem key={index} hit={hit} />
      ))}
    </section>
  );
};

export default CategoryResults;
