import { useInstantSearch } from 'react-instantsearch';
import ProductItem from '../items/ProductItem';
import Link from 'next/link';
import SearchHeading from '../components/SearchHeading';
import { useSearchContext } from '@/contexts/search';
import { Button } from '@fagmobler/ui';

const ProductResults = () => {
  const { results } = useInstantSearch();
  const { autocompleteState } = useSearchContext();

  if (results.hits.length === 0) {
    return <></>;
  }

  return (
    <section className="flex flex-col gap-2">
      <div className="flex justify-between items-center lg:ml-2">
        <SearchHeading className="p-0">Produkter</SearchHeading>
        {results.nbHits > 0 && (
          <Link
            href={`/sok?q=${autocompleteState.query}`}
            className="cap-link-sm text-neutral-80"
            tabIndex={-1}
          >
            <Button
              variant="outline"
              className="!rounded-[100px] hover:bg-neutral-80 hover:text-neutral-20"
              size="xs"
            >
              Vis alle {results.nbHits} treff
            </Button>
          </Link>
        )}
      </div>
      <ul className="flex flex-col gap-4 grow">
        {results.hits.map((hit, index) => (
          <li key={index} className="group hover:bg-neutral-30 rounded lg:px-2">
            <ProductItem hit={hit} />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ProductResults;
