import React from 'react';
import { Image } from './modules/Image';
import { Logo } from './modules/Logo';
import { Bubble } from './modules/Bubble';
import { DoubleBubble } from './modules/DoubleBubble';
import { Cta } from './modules/Cta';
import { Theme } from './modules/Theme';
import { Video } from '@/components/CampaignCardV3/modules/Video';
import { CampaignText } from '@/components/CampaignCardV3/modules/CampaignText';

type Slot = {
  _type:
    | 'background'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'center'
    | 'footer';
  modules: any[];
};

type CampaignCardV3Props = {
  slots: Slot[];
};

const slotStyles = {
  topLeft: 'col-start-1 col-end-2 row-start-1 row-end-2 ',
  topRight: 'col-start-3 col-end-4 row-start-1 row-end-2',
  bottomLeft: 'col-start-1 col-end-2 row-start-3 row-end-4',
  bottomRight: 'col-start-3 col-end-4 row-start-3 row-end-4',
  center: 'col-start-2 col-end-3 row-start-1 row-end-4',
  footer: 'col-start-1 col-end-4 row-start-4 row-end-5',
};

const modules = {
  image: Image,
  logo: Logo,
  bubble: Bubble,
  doubleBubble: DoubleBubble,
  cta: Cta,
  theme: Theme,
  video: Video,
  campaignText: CampaignText,
};

const getSlotStyle = (slotType: string) => {
  return slotStyles[slotType as keyof typeof slotStyles] || '';
};

const getModule = (moduleKey: string) => {
  const selectedModule = modules[moduleKey as keyof typeof modules];

  if (!selectedModule) {
    throw new Error(`Module ${moduleKey} not found`);
  }

  return selectedModule;
};

export const CampaignCardV3 = ({ slots }: CampaignCardV3Props) => {
  const backgroundSlot = slots.find((slot) => slot._type === 'background');
  const otherSlots = slots.filter((slot) => slot._type !== 'background');

  if (!backgroundSlot) {
    return null;
  }

  const BackgroundComponent = getModule(backgroundSlot.modules[0]._type);

  return (
    <div className="relative w-full h-full bg-neutral-20">
      <BackgroundComponent
        // @ts-ignore
        module={backgroundSlot.modules[0]}
        type="cover"
        slot="background"
      />
      <div className="absolute top-0 left-0 right-0 bottom-0 p-4 lg:p-6 grid gap-1 lg:gap-3 grid-cols-[3.5fr_3fr_3.5fr] grid-rows-[35%_1fr_35%_min-content] lg:grid-cols-[25%_1fr_25%] lg:grid-rows-[25%_1fr_25%_min-content]">
        {otherSlots.map((slot) => {
          return (
            <div key={slot._type} className={`${getSlotStyle(slot._type)}`}>
              {slot.modules.map((module) => {
                const ModuleComponent = getModule(module._type);

                return (
                  <ModuleComponent
                    key={module._id}
                    // @ts-ignore
                    module={module}
                    slot={slot._type}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
