import { TagIcon } from '@heroicons/react/24/outline';
import { useInstantSearch } from 'react-instantsearch';
import GenericTextItem from '../items/GenericTextItem';
import SearchHeading from '../components/SearchHeading';
import { useRouter } from 'next/router';

const StoreResults = () => {
  const { results } = useInstantSearch();
  const router = useRouter();

  if (results.hits.length === 0) {
    return <></>;
  }

  const formattedResults = results.hits.map((hit: any) => {
    return {
      ...hit,
      formattedOpeningHours: hit.formattedOpeningHours.replaceAll(', ', '\n'),
    };
  });

  const handleSelect = (hit: any): void => {
    router.push(`/${hit.path}`);
  };

  return (
    <section>
      <ul className="flex flex-col gap-2">
        {formattedResults.map((hit, index) => (
          <li key={index}>
            <GenericTextItem
              key={index}
              id={hit.id}
              sourceId={hit.sourceId}
              item={hit}
              prop="name"
              subtitleProp="formattedOpeningHours"
              highlight={false}
              onSelect={handleSelect}
              icon="store"
              onRemove={() => {}}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default StoreResults;
