import { Price } from '@fagmobler/ui';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { getCanonicalPath } from '@/lib/algolia/getCanonicalPath';

type ProductItemProps = {
  hit: any;
};

export default function ProductItem({ hit }: ProductItemProps) {
  const extractDescription = (
    content: string,
    maxNumberOfWords: number,
    trailingIndicator = '...'
  ) => {
    const listOfWords = content.trim().split(' ');
    const truncatedContent = listOfWords
      .slice(0, maxNumberOfWords)
      .join(' ')
      .replace(/,\s*$/, '');
    const excerpt = truncatedContent + trailingIndicator;
    return listOfWords.length > maxNumberOfWords ? excerpt : content;
  };

  const {
    path,
    images,
    posterName,
    posterDescription,
    price,
    memberPrice,
    campaignPrice,
  } = hit;

  const href = getCanonicalPath(hit);

  // Replace non-breaking space with regular space
  const fixedPosterName = posterName.replaceAll('\u00A0', ' ').trim();

  const image = images?.[0];
  return (
    <Link
      href={href}
      className="grid grid-cols-[104px,auto,80px] gap-2 text-neutral-80 items-center"
    >
      {image?.type === 'single' ? (
        <div className="bg-neutral-30 w-[104px] h-[87px] object-contain p-1 rounded-[4px] flex items-center justify-center">
          <Image
            src={image?.url}
            alt={posterName}
            width={104}
            height={87}
            className="max-w-full max-h-full object-contain mix-blend-multiply"
          />
        </div>
      ) : (
        <div className="relative w-[104px] h-[87px]">
          <Image
            src={image?.url}
            alt={posterName}
            fill={true}
            sizes="(max-width: 104px) 100vw, 104px"
            className="mix-blend-multiply object-cover rounded-[4px]"
          />
        </div>
      )}

      <div className="grow gap-[3px] flex flex-col">
        <span className="block hd-base">{fixedPosterName}</span>
        <p className="body-xs text-neutral-80">
          {posterDescription && extractDescription(posterDescription, 5)}
        </p>
      </div>
      <div className="min-w-20 flex justify-end">
        <Price
          price={price}
          memberPrice={memberPrice}
          campaignPrice={campaignPrice}
          size="xs"
          priceText="Pris fra"
          memberPriceText="Medlemspris fra"
          normalPriceText="Ordinær"
          withStickers={false}
          isSearch={true}
        />
      </div>
    </Link>
  );
}
