import { FagmoblerAlgoliaProduct } from '@fagmobler/types';
import { getPathFromCategorySlug } from '@/lib/algolia/utils';
import {
  MaterializedContentBlock,
  MaterializedImageWithPoints,
  MaterializedProductCollectionBlock,
} from '@fagmobler/sanity';
import { getPathFromInternalLink } from '@/lib/utils/getUrls';
import Image from 'next/image';
import {
  TextboxCard,
  CampaignCard,
  CampaignCardV2,
  ContentCard,
  ArticleCard,
  CategoryCollectionCard,
  ImageAndText,
  TopBanner,
  CampaignBanner,
} from '@fagmobler/ui';
import Link from 'next/link';
import { ALGOLIA_INDEX_NAME } from '@/lib/algolia/searchClient';
import { RenderProductCollection } from './ProductCollection';
import { useNavigation } from '@/contexts/navigation';
import { FagmoblerPortableText } from '../portableText';
import { ImageWithPoints } from '../images/ImageWithPoints';
import { ImageCollection } from '../images/ImageCollection';
import Debug from '../debug';
import DefaultImage, { defaultSizes } from '../images/DefaultImage';
import { PortableText } from '@portabletext/react';
import { ProductCategories } from '../productCategories';
import { getCategoriesResult } from '@/lib/categories/getCategories';
import { ContentBlocksGrid } from './ContentBlocksGrid';
import { BlockWrapper } from './BlockWrapper';
import { trackEvent } from '@/lib/analytics';
import { useStores } from '../store';
import clsx from 'clsx';
import { imageSizes } from '@/utils/imageSizes';
import { imagePriority } from '@/utils/imagePriority';
import { twMerge } from 'tailwind-merge';
import { CampaignCardV3 } from '@/components/CampaignCardV3';
import NextImage from 'next/image';

type ContentBlocksRendererProps = {
  priority?: boolean;
  blocks?: MaterializedContentBlock[] | null;
  getSize?(block: MaterializedContentBlock, i: number): 'sm' | 'md' | 'lg';
  fullWidth?: boolean;
};

export type MaterializedImageWithPointsWithProducts =
  | (MaterializedImageWithPoints & {
      points: (MaterializedImageWithPoints['points'] & {
        product: FagmoblerAlgoliaProduct | null;
      })[];
    })
  | null;

export const populateImageWithPointsWithProducts = async (
  imageWithPoints: MaterializedImageWithPoints
): Promise<MaterializedImageWithPointsWithProducts> => {
  if (!imageWithPoints.points) return null;
  const { searchClient } = await import('@/lib/algolia/searchClient');
  const index = searchClient.initIndex(ALGOLIA_INDEX_NAME);
  // Image with points
  if (imageWithPoints.points?.length > 0) {
    const SKUs = imageWithPoints.points.map((point: any) => point.productSKU);
    const products = await index
      .getObjects<FagmoblerAlgoliaProduct>(SKUs.filter(Boolean))
      .then(
        (data) => data.results.filter(Boolean) as FagmoblerAlgoliaProduct[]
      );

    return {
      ...imageWithPoints,
      points: imageWithPoints.points.map((point: any) => {
        const product =
          products.find((p) => p.objectID === point.productSKU) || null;
        return {
          ...point,
          product,
        };
      }),
    };
  }
  return null;
};

export const populateBlockWithProducts = async (
  block: MaterializedProductCollectionBlock
) => {
  const { searchClient } = await import('@/lib/algolia/searchClient');
  const index = searchClient.initIndex(ALGOLIA_INDEX_NAME);

  if (typeof window !== 'undefined') {
    console.warn('Getting products on client side');
  }

  if (!block.productSKUs || block.productSKUs.length === 0) {
    console.log('No products found');
    return [];
  }

  return index
    .getObjects<FagmoblerAlgoliaProduct>(block.productSKUs.filter(Boolean))
    .then((data) => data.results.filter(Boolean) as FagmoblerAlgoliaProduct[]);
};

const calculateImageSizes = (size: 'sm' | 'md' | 'lg' | 'xl') => {
  return size === 'sm'
    ? imageSizes({
        base: '100vw',
        sm: '50vw',
        md: '50vw',
        lg: '50vw',
        xl: '25vw',
        xxl: '25vw',
      })
    : size === 'md'
    ? imageSizes({
        base: '100vw',
        sm: '50vw',
        md: '50vw',
        lg: '50vw',
        xl: '50vw',
        xxl: '50vw',
      })
    : size === 'lg'
    ? imageSizes({
        base: '100vw',
      })
    : imageSizes({
        base: '100vw',
      }); //XL
};

export const ContentBlocksRenderer = ({
  blocks,
  priority,
  getSize = () => 'lg',
  fullWidth = false,
}: ContentBlocksRendererProps) => {
  const { setShowClubMemberForm } = useNavigation();
  const { current } = useStores();
  const currentStore = current.data?.store || undefined;

  return (
    <>
      {blocks?.map((block, i) => {
        if (!('_type' in block)) return;

        // Reusable contentblock
        if (block._type == 'reusableContentBlock' && block.contentBlocks) {
          return (
            <ContentBlocksRenderer
              priority={priority}
              blocks={block.contentBlocks}
              key={block._id}
            />
          );
        }

        // Top banner
        if (block._type === 'topBanner') {
          const href =
            block.internalLink && getPathFromInternalLink(block.internalLink);
          return (
            <BlockWrapper
              key={block._key}
              className={twMerge(
                clsx('block w-full relative h-full !mt-2 !mb-0', {
                  'w-screen ml-[-50vw] left-1/2 !-mt-4': block.fullWidth,
                })
              )}
              onView={() => {
                trackEvent(
                  'view_promotion',
                  {
                    creative_name: block.title,
                  },
                  currentStore
                );
              }}
            >
              {href ? (
                <Link href={href} key={block._key} className="block">
                  <TopBanner
                    title={block.title}
                    theme={block.theme}
                    cta={block.cta}
                    type={block.type}
                    countdownEnd={block.countdownEnd}
                    countdownImage={block.countdownImage}
                    NextImage={NextImage}
                  />
                </Link>
              ) : (
                <div className="block">
                  <TopBanner
                    title={block.title}
                    theme={block.theme}
                    cta={block.cta}
                    type={block.type}
                    countdownEnd={block.countdownEnd}
                    countdownImage={block.countdownImage}
                    NextImage={NextImage}
                  />
                </div>
              )}
            </BlockWrapper>
          );
        }

        // Campaign banner
        if (block._type === 'campaignBanner') {
          const href =
            block.internalLink && getPathFromInternalLink(block.internalLink);
          if (!href) return null;
          return (
            <BlockWrapper
              key={block._key}
              className="block w-full relative  h-full"
              onView={() => {
                trackEvent(
                  'view_promotion',
                  {
                    creative_name: block.title,
                  },
                  currentStore
                );
              }}
            >
              <Link href={href} key={block._key}>
                <CampaignBanner
                  theme={block.theme}
                  title={block.title}
                  cta={block.cta}
                >
                  {block.text}
                </CampaignBanner>
              </Link>
            </BlockWrapper>
          );
        }

        // Full width campaign banner
        if (block._type === 'fullWidthCampaignBanner') {
          const href =
            block.internalLink &&
            getPathFromInternalLink(block.internalLink, block);

          if (!href || !block.mainImage) return null;

          return (
            <BlockWrapper
              key={block._key}
              onView={() => {
                trackEvent(
                  'view_promotion',
                  {
                    creative_name: block.title,
                  },
                  currentStore
                );
              }}
            >
              <Link href={href} key={block._key}>
                {block.mainImage.croppedImageUrl && (
                  <Image
                    sizes={defaultSizes}
                    className="object-cover h-full w-full"
                    src={block.mainImage.croppedImageUrl}
                    placeholder={block.mainImage.lqip ? 'blur' : undefined}
                    blurDataURL={block.mainImage.lqip || undefined}
                    width={block.mainImage.width || 0}
                    height={block.mainImage.height || 0}
                    alt={block.title || 'Kampanjebilde'}
                    priority={priority}
                  />
                )}
              </Link>
            </BlockWrapper>
          );
        }

        // Content blocks grid
        if (block._type == 'contentBlocksGrid' && block.content) {
          const { content, columns, _key, fullWidth } = block;
          if (columns === 1 || columns == 2 || columns == 3 || columns == 4) {
            return (
              <ContentBlocksGrid
                columns={columns}
                content={content}
                fullWidth={fullWidth}
                key={_key}
                priority={priority}
              />
            );
          }
        }

        // Campaign card V2
        if (block._type === 'campaignCardV2') {
          const href =
            block.internalLink &&
            getPathFromInternalLink(block.internalLink, block);

          if (!href) return null;

          const size = getSize(block, i);
          const computedImageSizes = calculateImageSizes(size);

          return (
            <BlockWrapper
              key={block._key}
              className="block w-full relative  h-full"
              onView={() => {
                trackEvent(
                  'view_promotion',
                  {
                    creative_name: block.title,
                  },
                  currentStore
                );
              }}
            >
              <Link
                href={href}
                className="block w-full  h-full"
                onClick={() => {
                  trackEvent(
                    'select_promotion',
                    {
                      creative_name: block.title,
                    },
                    currentStore
                  );
                }}
              >
                <CampaignCardV2
                  {...block}
                  size={size}
                  fullWidth={fullWidth}
                  image={
                    block.mainImage?.croppedImageUrl && (
                      <Image
                        className="object-cover h-full w-full"
                        src={block.mainImage?.croppedImageUrl}
                        placeholder={block.mainImage?.lqip ? 'blur' : undefined}
                        blurDataURL={block.mainImage?.lqip || undefined}
                        alt={block.title || 'Kampanjebilde'}
                        priority={imagePriority(priority, i)}
                        sizes={computedImageSizes}
                        fill
                      />
                    )
                  }
                />
              </Link>
            </BlockWrapper>
          );
        }

        if (block._type === 'campaignCardV3') {
          const href =
            block.internalLink &&
            getPathFromInternalLink(block.internalLink, block);

          if (href) {
            return (
              <Link
                key={block._key}
                href={href}
                className="block w-full h-full"
                onClick={() => {
                  trackEvent(
                    'select_promotion',
                    {
                      creative_name: block.title,
                    },
                    currentStore
                  );
                }}
              >
                <CampaignCardV3 slots={block.slots} />
              </Link>
            );
          }

          return <CampaignCardV3 key={block._key} slots={block.slots} />;
        }

        // Campaign card
        if (block._type === 'campaignCard') {
          const href =
            block.internalLink &&
            getPathFromInternalLink(block.internalLink, block);

          if (!href) return null;

          const size = getSize && getSize(block, i);
          const computedImageSizes = calculateImageSizes(size);

          return (
            <BlockWrapper
              key={block._key}
              className="block w-full h-full relative"
              onView={() => {
                trackEvent(
                  'view_promotion',
                  {
                    creative_name: block.title,
                  },
                  currentStore
                );
              }}
            >
              <Link
                href={href}
                key={block._key}
                className="block w-full h-full relative"
              >
                <CampaignCard
                  {...block}
                  fill
                  image={
                    block.mainImage?.croppedImageUrl && (
                      <Image
                        className="object-cover h-full w-full"
                        src={block.mainImage?.croppedImageUrl}
                        placeholder={block.mainImage?.lqip ? 'blur' : undefined}
                        blurDataURL={block.mainImage?.lqip || undefined}
                        alt={block.title || 'Kampanjebilde'}
                        priority={imagePriority(priority, i)}
                        sizes={computedImageSizes}
                        fill
                      />
                    )
                  }
                  size={size}
                />
              </Link>
            </BlockWrapper>
          );
        }

        // Textbox card
        if (block?._type === 'textboxCard') {
          return <TextboxCard {...block} key={block._key} />;
        }

        // Content card
        if (block?._type === 'contentCard') {
          // Legacy body text is removed rom types
          // @ts-ignore
          const legacyBodyText: string = block.body || '';
          const href =
            block.internalLink &&
            getPathFromInternalLink(block.internalLink, block);
          if (!href) return null;
          return (
            <Link key={block._key} href={href} className="block w-full">
              <ContentCard
                {...block}
                body={
                  block.simpleText ? (
                    <FagmoblerPortableText value={block.simpleText} />
                  ) : (
                    legacyBodyText
                  )
                }
                href={href}
                image={
                  block.mainImage?.croppedImageUrl && (
                    <Image
                      src={block.mainImage?.croppedImageUrl}
                      placeholder={block.mainImage.lqip ? 'blur' : undefined}
                      blurDataURL={block.mainImage?.lqip || undefined}
                      alt={block.title || 'Kampanjebilde'}
                      fill
                      priority={priority}
                    />
                  )
                }
                size={getSize && getSize(block, i)}
              />
            </Link>
          );
        }

        // Article card
        if (block._type === 'articleCard') {
          const href =
            block.internalLink && getPathFromInternalLink(block.internalLink);
          if (!href) return null;
          return (
            <Link key={block._key} href={href} className="w-full">
              <ArticleCard
                {...block}
                title={block.title || 'Tittel'}
                body={block.body || 'Tekst'}
                image={
                  block.mainImage?.croppedImageUrl && (
                    <Image
                      src={block.mainImage?.croppedImageUrl}
                      placeholder={block.mainImage?.lqip ? 'blur' : undefined}
                      blurDataURL={block.mainImage?.lqip || undefined}
                      alt={
                        block.mainImage?.alt || block.title || 'Artikkelbilde'
                      }
                      fill
                      priority={priority}
                    />
                  )
                }
                size={getSize && getSize(block, i)}
              />
            </Link>
          );
        }

        if (block._type === 'categoryCollection') {
          return (
            <CategoryCollectionCard
              {...block}
              getHref={getPathFromCategorySlug}
              key={block?._key}
            />
          );
        }

        // Image collection
        if (block._type === 'imageCollection' && block.images) {
          return (
            <ImageCollection
              key={block._key}
              images={block.images}
              aspectRatio={block.aspectRatio}
              title={block.title}
            />
          );
        }

        // Product collection
        if (block._type === 'productCollection') {
          return <RenderProductCollection {...block} key={block._key} />;
        }

        // Image with points
        if (block._type === 'imageWithPointsDocument') {
          return block.imageWithPoints ? (
            <ImageWithPoints
              imageWithPoints={block.imageWithPoints}
              sizes={defaultSizes}
            />
          ) : null;
        }

        // Image with points
        if (block._type === 'imageAndText') {
          const image = block.mainImage;

          if (!image || !image.croppedImageUrl) {
            return null;
          }

          // Creates a square crop and adjust the crop to match the focal point
          const squareImageUrl = new URL(image.croppedImageUrl);
          squareImageUrl.searchParams.set('fit', 'crop');
          squareImageUrl.searchParams.set('h', '5000');
          squareImageUrl.searchParams.set('crop', 'focalpoint');
          const imageSrc = squareImageUrl.href;
          return (
            <ImageAndText
              {...block}
              key={block._key}
              image={
                imageSrc && (
                  <DefaultImage
                    alt={image.alt || 'Bilde'}
                    src={imageSrc}
                    fill
                    className="aspect-w-1 aspect-h-1"
                    sizes="(max-width: 640px) 100vw, 460px"
                  />
                )
              }
              body={
                block.body ? <PortableText value={block.body} /> : undefined
              }
            />
          );
        }

        if (block._type === 'memberCard') {
          const src = block.mainImage?.croppedImageUrl;
          return (
            <ContentCard
              key={block._key}
              {...block}
              onClick={() => setShowClubMemberForm(true)}
              image={
                src && (
                  <Image src={src} fill alt="Meld deg inn i kundeklubben" />
                )
              }
              className="my-20"
            />
          );
        }

        if (block._type === 'productCategoryEnrichment') {
          // @ts-ignore
          const categories = block.categories as getCategoriesResult;
          return (
            <div key={block._key}>
              <h2 className="text-2xl font-semibold mb-4">{block.title}</h2>
              <ProductCategories categories={categories} />
            </div>
          );
        }

        return <Debug data={block} key={block._key} />;
      })}
    </>
  );
};
