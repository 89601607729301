import { TSearchMenuItem } from '@/lib/sanity/queries';
import Link from 'next/link';

type LinkItemProps = {
  item: TSearchMenuItem;
};

export default function LinkItem({ item }: LinkItemProps) {
  const getSearchMenuLink = (item: TSearchMenuItem) => {
    if (item.category && item.category === 'inspirasjon') {
      return `/inspirasjon/${item.slug}`;
    }
    return `/${item.slug}`;
  };

  return (
    <Link
      href={getSearchMenuLink(item)}
      className="cap-link-sm font-bold text-neutral-80"
    >
      {item.title}
    </Link>
  );
}
