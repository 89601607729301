import { MOBILE_BREAKPOINT, useSearchContext } from '@/contexts/search';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import AutocompleteModal from './AutocompleteModal';
import SearchInput from './components/SearchInput';
import ResultsModal from './ResultsModal';
import { useEffect } from 'react';
import { useNavigation } from '@/contexts/navigation';
import useWindowDimensions from '@/hooks/window-dimensions';

const Search = () => {
  const { setShowSearch } = useNavigation();
  const { wrapperRef, autocompleteState, showResults } = useSearchContext();
  const { width } = useWindowDimensions();

  const isModalOpen = autocompleteState.isOpen || showResults;

  useEffect(() => {
    setShowSearch(isModalOpen);
  }, [isModalOpen, width]);

  return (
    <div
      ref={wrapperRef}
      className={twMerge(
        clsx(
          'bg-neutral-0 relative w-full max-w-[750px] self-center rounded-full',
          {
            'lg:rounded-t-2xl lg:rounded-b-none': isModalOpen,
          }
        )
      )}
    >
      <SearchInput />
      {autocompleteState.isOpen && <AutocompleteModal className="" />}
      {showResults && (
        <ResultsModal className="w-screen -left-4 px-4 h-screen pb-[60vh] overflow-y-auto overscroll-contain md:left-0 md:px-0 md:w-full md:h-auto md:pb-0 md:overflow-auto" />
      )}
    </div>
  );
};

export default Search;
