import React, { useRef, useState } from 'react';
import MuxPlayer from '@mux/mux-player-react/lazy';
import { PauseIcon, PlayIcon } from '@heroicons/react/20/solid';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type VideoProps = {
  slot?: string;
  module: {
    _type: 'video';
    _key: string;
    isPrimary?: boolean;
    video: {
      asset: {
        playbackId: string;
      };
    };
  };
};

export const Video = ({ module, slot }: VideoProps) => {
  const [paused, setPaused] = useState(false);
  const playerRef = useRef<{ media: { nativeEl: HTMLVideoElement } }>(null);
  const style = {
    '--controls': 'none',
    '--media-object-fit': 'cover',
    '--media-object-position': 'center',
    width: '100%',
    height: '100%',
  } as React.CSSProperties;

  const handlePlayPause = () => {
    if (playerRef.current) {
      setPaused((prev) => !prev);
      if (paused) {
        playerRef.current.media.nativeEl.play();
      } else {
        playerRef.current.media.nativeEl.pause();
      }
    }
  };

  return (
    <div
      className={twMerge(
        clsx('relative size-full', {
          'overlay-after': slot === 'background',
        })
      )}
    >
      <MuxPlayer
        // @ts-ignore
        ref={playerRef}
        loading={module.isPrimary ? 'page' : 'viewport'}
        muted={true}
        autoPlay={true}
        loop={true}
        paused={paused}
        nohotkeys={true}
        themeProps={{
          playerControls: {
            enabled: false,
          },
        }}
        style={style}
        playbackId={module.video.asset.playbackId}
      />
      <button
        className="absolute top-2 right-2 z-10"
        onClick={handlePlayPause}
        aria-label={paused ? 'Play' : 'Pause'}
      >
        {paused ? (
          <PlayIcon className="size-10 text-white drop-shadow" />
        ) : (
          <PauseIcon className="size-10 text-white drop-shadow" />
        )}
      </button>
    </div>
  );
};
