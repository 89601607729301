import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { getThemeStyles } from '@/components/CampaignCardV3/modules/Theme';
import { AutoSizedText } from '@/components/CampaignCardV3/modules/AutoSizedText';

type BubbleProps = {
  slot?: string;
  module: {
    _type: 'bubble';
    _key: string;
    theme: string;
    upperText?: string;
    mainText: string;
  };
};

export const Bubble = ({ module, slot }: BubbleProps) => {
  return (
    <div
      className={twMerge(
        clsx('w-full h-full flex items-center justify-center font-heading', {
          'items-end justify-start': slot === 'bottomLeft',
          'items-end justify-end': slot === 'bottomRight',
          'items-start justify-start': slot === 'topLeft',
          'items-start justify-end': slot === 'topRight',
        })
      )}
    >
      <span
        className={twMerge(
          clsx(
            'flex flex-col p-2 lg:p-3 justify-center items-center h-full aspect-square rounded-full @container',
            {
              'h-[50%] lg:h-[60%] p-4': slot === 'center',
            },
            getThemeStyles(module.theme, false),
            'rounded-full flex justify-center items-center'
          )
        )}
      >
        {module.upperText && (
          <span className={twMerge(clsx('block hd-base tracking-normal'))}>
            {module.upperText}
          </span>
        )}
        <AutoSizedText>{module.mainText}</AutoSizedText>
      </span>
    </div>
  );
};
