import clsx from 'clsx';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import type {
  Breadcrumb as TBreadcrumb,
  Breadcrumbs as TBreadcrumbs,
} from '@fagmobler/types';
import Link from 'next/link';
import { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export function BreadcrumbList({
  children,
  className,
}: {
  children: ReactNode;
  className: string;
}) {
  return (
    <nav aria-label="Breadcrumb">
      <ol
        className={twMerge(
          clsx('flex items-center sm:space-x-1.5 flex-wrap', className)
        )}
      >
        {children}
      </ol>
    </nav>
  );
}

export function BreadcrumbListItem({
  isFirst,
  isCurrent,
  children,
  showFullWidth = false,
}: {
  isFirst?: boolean;
  isCurrent?: boolean;
  children: React.ReactNode;
  showFullWidth?: boolean;
}) {
  return (
    <li
      className={clsx(
        'flex',
        isCurrent && 'flex-1',
        { 'min-w-0': !showFullWidth },
        'items-center',
        'whitespace-nowrap'
      )}
    >
      {!isFirst && (
        <ChevronRightIcon
          className={clsx(
            'flex-shrink-0',
            'h-5 w-5',
            'text-neutral-70',
            'text-sm'
          )}
          aria-hidden="true"
        />
      )}

      {children}
    </li>
  );
}

export function BreadcrumbItem({
  name,
  isCurrent,
  path,
  isFirst,
  onClick,
  showFullWidth = false,
}: TBreadcrumb & {
  isFirst?: boolean;
  onClick?: () => void;
  showFullWidth?: boolean;
}) {
  return (
    <BreadcrumbListItem
      showFullWidth={showFullWidth}
      isCurrent={isCurrent}
      isFirst={isFirst}
    >
      {isCurrent ? (
        <span
          className={clsx(
            'flex-0',
            !isFirst && 'sm:ml-1.5',
            { 'overflow-hidden overflow-ellipsis': !showFullWidth },
            'text-neutral-80',
            'underline text-sm font-medium underline-offset-2'
          )}
        >
          {name}
        </span>
      ) : (
        <Link
          href={path}
          className={clsx(
            !isFirst && 'sm:ml-1.5',
            'whitespace-nowrap',
            { 'min-w-0 overflow-hidden overflow-ellipsis': !showFullWidth },
            'hover:underline text-neutral-70 text-sm font-medium underline-offset-2'
          )}
          onClick={(event) => {
            if (onClick) {
              event.preventDefault();
              onClick();
            }
          }}
          aria-current={isCurrent ? 'page' : undefined}
        >
          {name}
        </Link>
      )}
    </BreadcrumbListItem>
  );
}

export interface BreadcrumbsProps {
  breadcrumbs: TBreadcrumbs;
}

export function Breadcrumbs({ breadcrumbs = [] }: BreadcrumbsProps) {
  const [isMobileExpanded, setIsMobileExpanded] = useState(false);
  const crumbs = [
    {
      name: 'Fagmøbler.no',
      path: '/',
    },
    ...breadcrumbs.filter((crumb) => crumb.path !== '/'),
  ];
  const mobileCrumbs = isMobileExpanded ? crumbs : crumbs.slice(-2);
  const hasMoreCrumbs = crumbs.length > mobileCrumbs.length;

  return (
    <>
      <BreadcrumbList className="hidden md:flex">
        {crumbs.map((crumb, i) => (
          <BreadcrumbItem
            isFirst={i === 0}
            key={i}
            {...crumb}
            isCurrent={i === crumbs.length - 1}
          />
        ))}
      </BreadcrumbList>
      <BreadcrumbList className="md:hidden">
        {hasMoreCrumbs && (
          <BreadcrumbItem
            isFirst
            path="#"
            name="..."
            onClick={() => setIsMobileExpanded(true)}
          />
        )}
        {mobileCrumbs.map((crumb, i) => (
          <BreadcrumbItem
            isFirst={!hasMoreCrumbs && i === 0}
            key={i}
            {...crumb}
            isCurrent={i === crumbs.length - 1}
            showFullWidth={isMobileExpanded}
          />
        ))}
      </BreadcrumbList>
    </>
  );
}

export default Breadcrumbs;
