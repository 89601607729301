import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

type ArticleItemProps = {
  hit: any;
};

export default function ArticleItem({ hit }: ArticleItemProps) {
  const { title, mainImage, slug, estimatedReadingTime } = hit;
  return (
    <Link href={`/inspirasjon/${slug}`} className="w-full flex flex-col gap-2">
      {mainImage.url ? (
        <Image
          src={mainImage.url}
          alt={title}
          width={478}
          height={250}
          className="w-full h-[109px] lg:h-[81px] object-cover rounded-[4px]"
        />
      ) : (
        <div className="w-full h-[109px] lg:h-[81px] object-cover rounded-[4px] bg-neutral-50"></div>
      )}
      <div>
        <span className="block body-xs text-neutral-80">{title}</span>
        {estimatedReadingTime && (
          <span className="block body-xs text-neutral-70">
            {estimatedReadingTime} min
          </span>
        )}
      </div>
    </Link>
  );
}
