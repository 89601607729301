import dynamic from 'next/dynamic';

import {
  ArticleCard,
  CampaignCard,
  ContentCard,
  ImageAndText,
  TextboxCard,
  CampaignTabs,
  TopBanner,
} from '@fagmobler/ui';
import Image from 'next/image';
import { PortableText, PortableTextProps } from '@portabletext/react';
import { RenderProductCollection } from '@/components/contentBlocks/ProductCollection';
import {
  ImageWithCaption,
  defaultSizes,
} from '@/components/images/DefaultImage';
import { getPathFromInternalLink } from '@/lib/utils/getUrls';
import Link from 'next/link';
import { useNavigation } from '@/contexts/navigation';
import clsx from 'clsx';
import type {
  Body,
  BodyImageAndText,
  BodyImageCollection,
  Lead,
  MaterializedArticleCard,
  MaterializedBody,
  MaterializedContentCard,
  MaterializedImageWithCrop,
  MaterializedMemberCard,
  MaterializedVideo,
  SanityBlock,
  SimpleText,
  TextboxCardBlock,
} from '@fagmobler/sanity';
import { MaterializedContentBlockWithUrl } from '@/lib/sanity/queries/blocks';
import { ImageWithPoints } from '../images/ImageWithPoints';
import { ImageCollection } from '../images/ImageCollection';
import { ContentBlocksGrid } from '../contentBlocks/ContentBlocksGrid';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { CampaignCardV3 } from '../CampaignCardV3';
import NextImage from 'next/image';
import { twMerge } from 'tailwind-merge';

const MuxPlayer = dynamic(() => import('@mux/mux-player-react'), {
  ssr: false,
});

type FagmoblerPortableTextProps = {
  value: MaterializedBody | Lead | SimpleText | Body;
  baseClassName?: string;
  forbidNegativeMargins?: boolean;
};

export const FagmoblerPortableText = ({
  value,
  baseClassName,
  forbidNegativeMargins = false,
}: FagmoblerPortableTextProps) => {
  const router = useRouter();
  const [activeCampaignSlug, setActiveCampaignSlug] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (router.query.slugs) {
      setActiveCampaignSlug(router.query.slugs[0]);
    } else if (router.query.slug) {
      setActiveCampaignSlug(
        Array.isArray(router.query.slug)
          ? router.query.slug[0]
          : router.query.slug
      );
    } else {
      setActiveCampaignSlug(null);
    }
  }, [router.query]);

  const { setShowClubMemberForm } = useNavigation();
  const portableTextComponents: PortableTextProps['components'] = {
    list: {
      bullet: ({ children }) => (
        <ul className="body-base leading-snug mx mb-10">{children}</ul>
      ),
    },
    listItem: ({ children }) => (
      <li className="my-3 ml-10 list-disc">{children}</li>
    ),
    marks: {
      internalLinkMark: (mark) => {
        const href =
          mark.value.internalLink &&
          getPathFromInternalLink(mark.value.internalLink, mark.value);

        if (!href) {
          return <span>{mark.children}</span>;
        }

        return (
          <Link href={href} className="underline underline-offset-1">
            {mark.children}
          </Link>
        );
      },
      link: (mark) => {
        const rel = !mark.value.href.startsWith('/')
          ? 'noreferrer noopener'
          : undefined;

        return (
          <a
            href={mark.value.href}
            rel={rel}
            className="underline-offset-2 underline"
          >
            {mark.children}
          </a>
        );
      },
    },
    block: {
      h1: ({ children }) => (
        <h1 className="hd-lg md:hd-xl mt-10 mb-4 max-w-2xl">{children}</h1>
      ),
      h2: ({ children }) => (
        <h2 className="hd-lg md:hd-xl mt-10 mb-4 max-w-2xl">{children}</h2>
      ),
      h3: ({ children }) => (
        <h3 className="hd-lg md:hd-xl mt-10 mb-4 max-w-2xl">{children}</h3>
      ),
      h4: ({ children }) => (
        <h3 className="hd-lg md:hd-xl mt-10 mb-4 max-w-2xl">{children}</h3>
      ),
      normal: ({ children }) => (
        <p
          className={twMerge(
            clsx('body-base leading-loose mx mb-10 max-w-2xl', baseClassName)
          )}
        >
          {children}
        </p>
      ),
      blockquote: ({ children }) => (
        <blockquote className="leading-loose mx mb-10 border-l-8 border-neutral-40 p-10 hd-base md:hd-md max-w-xl mx-auto">
          {children}
        </blockquote>
      ),
    },
    types: {
      topBanner: (type) => {
        const { value } = type;

        if (value._type === 'topBanner') {
          const href =
            value.internalLink && getPathFromInternalLink(value.internalLink);

          return (
            <div
              className={clsx('my-20', {
                'lg:-mx-16 xl:-mx-44': !forbidNegativeMargins,
              })}
            >
              {href ? (
                <Link href={href} key={value._key} className="block">
                  <TopBanner
                    title={value.title}
                    theme={value.theme}
                    cta={value.cta}
                    type={value.type}
                    countdownEnd={value.countdownEnd}
                    countdownImage={value.countdownImage}
                    NextImage={NextImage}
                  />
                </Link>
              ) : (
                <div className="block">
                  <TopBanner
                    title={value.title}
                    theme={value.theme}
                    cta={value.cta}
                    type={value.type}
                    countdownEnd={value.countdownEnd}
                    countdownImage={value.countdownImage}
                    NextImage={NextImage}
                  />
                </div>
              )}
            </div>
          );
        }

        return null;
      },
      contentBlocksGrid: (type) => {
        const { value } = type;

        if (value._type === 'contentBlocksGrid') {
          return (
            <div
              className={clsx('my-20', {
                'lg:-mx-16 xl:-mx-44': !forbidNegativeMargins,
              })}
            >
              <ContentBlocksGrid {...value} />
            </div>
          );
        }

        return null;
      },
      quote: (type) => {
        return (
          <figure className="border-l-8 border-neutral-40 p-5 md:p-10 hd-base max-w-xl mx-auto">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src="/gfx/quote.svg"
              alt=""
              width="35"
              height="31"
              className="mb-5"
            />
            <blockquote cite={type.value.url} className="md:hd-md">
              {type.value.text}
            </blockquote>
            {type.value.author && (
              <figcaption className="mt-4">— {type.value.author}</figcaption>
            )}
          </figure>
        );
      },
      image: ({ value }: { value: MaterializedImageWithCrop }) => (
        <div
          className={clsx('relative my-20', {
            'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
          })}
        >
          {value.url && value.width && value.height && (
            <ImageWithCaption
              src={value.url}
              image={value}
              width={value.width}
              height={value.height}
              alt={value.alt || 'Bilde'}
              placeholder={value.lqip ? 'blur' : undefined}
              blurDataURL={value.lqip || undefined}
            />
          )}
        </div>
      ),
      imageAndText: ({ value }: { value: BodyImageAndText }) => {
        const image = value.mainImage;

        if (!image.croppedImageUrl) {
          return null;
        }

        // Creates a square crop and adjust the crop to match the focal point
        const squareImageUrl = new URL(image.croppedImageUrl);
        squareImageUrl.searchParams.set('fit', 'crop');
        squareImageUrl.searchParams.set('h', '5000');
        squareImageUrl.searchParams.set('crop', 'focalpoint');
        const imageSrc = squareImageUrl.href;

        return (
          <div
            className={clsx('relative my-20', {
              'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
            })}
          >
            <ImageAndText
              {...value}
              image={
                imageSrc && (
                  <Image
                    alt={value.mainImage.alt || 'Bilde'}
                    src={imageSrc}
                    fill
                    className="aspect-w-1 aspect-h-1"
                    sizes="(max-width: 640px) 100vw, 460px"
                    placeholder={value.mainImage.lqip ? 'blur' : undefined}
                    blurDataURL={value.mainImage.lqip || undefined}
                  />
                )
              }
              body={
                value.body ? <PortableText value={value.body} /> : undefined
              }
            />
          </div>
        );
      },
      imageCollection: ({ value }: { value: BodyImageCollection }) => (
        <div
          className={clsx('relative my-20', {
            'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
          })}
        >
          <ImageCollection
            images={value.images}
            aspectRatio={value.aspectRatio}
            title={value.title}
          />
        </div>
      ),
      productCollection: (block) => {
        const { value } = block;

        return (
          <RenderProductCollection
            {...value}
            key={value._key}
            className={clsx('my-20', {
              'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
            })}
          />
        );
      },
      campaignCard: ({ value }: { value: MaterializedContentCard }) => {
        const href =
          value.internalLink &&
          getPathFromInternalLink(value.internalLink, value);

        const image = value.mainImage?.croppedImageUrl && (
          <Image
            src={value.mainImage.croppedImageUrl}
            fill
            alt={value.mainImage.alt || value.title || 'Bilde'}
            placeholder={value.mainImage.lqip ? 'blur' : undefined}
            blurDataURL={value.mainImage.lqip || undefined}
          />
        );

        if (href) {
          return (
            <Link
              className={clsx('block my-20', {
                'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
              })}
              href={href}
            >
              <CampaignCard {...value} showButton={false} image={image} />
            </Link>
          );
        }

        return <CampaignCard {...value} showButton={false} image={image} />;
      },
      contentCard: ({
        value,
      }: {
        value: MaterializedContentCard & MaterializedContentBlockWithUrl;
      }) => {
        // @ts-ignore
        const legacyBodyText: string = value.body || '';

        const href =
          value.internalLink?.generatedUrl ||
          (value.internalLink &&
            getPathFromInternalLink(value.internalLink, value));

        const { mainImage } = value;

        if (!href) {
          return null;
        }

        const src = mainImage?.croppedImageUrl;

        return (
          <Link
            className={clsx('block my-20', {
              'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
            })}
            href={href}
          >
            <ContentCard
              {...value}
              body={
                value.simpleText ? (
                  <FagmoblerPortableText value={value.simpleText} />
                ) : (
                  legacyBodyText
                )
              }
              image={
                mainImage &&
                src && (
                  <Image
                    src={src}
                    fill
                    alt={mainImage.alt || value.title || 'Bilde'}
                    placeholder={mainImage.lqip ? 'blur' : undefined}
                    blurDataURL={mainImage.lqip || undefined}
                  />
                )
              }
            />
          </Link>
        );
      },
      memberCard: ({ value }: { value: MaterializedMemberCard }) => {
        const src = value.mainImage?.croppedImageUrl;

        return (
          <div
            className={clsx('block my-20', {
              'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
            })}
          >
            <ContentCard
              {...value}
              onClick={() => setShowClubMemberForm(true)}
              image={
                src && (
                  <Image src={src} fill alt="Meld deg inn i kundeklubben" />
                )
              }
              className="my-20"
            />
          </div>
        );
      },
      textboxCard: ({ value }: { value: TextboxCardBlock }) => (
        <div
          className={clsx('my-20', {
            'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
          })}
        >
          <TextboxCard {...value} />
        </div>
      ),
      video: ({ value }: { value: MaterializedVideo }) => {
        if (value.playbackId) {
          return (
            <div
              className={clsx('my-20', {
                'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
              })}
            >
              <MuxPlayer
                playbackId={value.playbackId}
                streamType="on-demand"
                style={{ aspectRatio: `${16 / 9}` }}
                poster={`https://image.mux.com/${
                  value.playbackId
                }/thumbnail.png?time=${value.thumbTime || 0}`}
              />
            </div>
          );
        }

        return null;
      },
      articleCard: ({
        value,
      }: {
        value: MaterializedArticleCard & MaterializedContentBlockWithUrl;
      }) => {
        const { internalLink, mainImage } = value;
        const src = mainImage?.croppedImageUrl;
        const href =
          internalLink?.generatedUrl ||
          (internalLink && getPathFromInternalLink(internalLink, value));

        if (href && mainImage) {
          return (
            <Link
              href={href}
              className={clsx('block my-20', {
                'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
              })}
            >
              <ArticleCard
                {...value}
                image={
                  src && (
                    <Image
                      src={src}
                      fill
                      alt={mainImage.alt || value.title || 'Bilde'}
                      placeholder={mainImage.lqip ? 'blur' : undefined}
                      blurDataURL={mainImage.lqip || undefined}
                    />
                  )
                }
              />
            </Link>
          );
        }
        return null;
      },
      imageWithPointsDocument: ({ value }) => {
        return value.imageWithPoints ? (
          <ImageWithPoints
            imageWithPoints={value.imageWithPoints}
            sizes={defaultSizes}
          />
        ) : null;
      },
      reusableContentBlock: () => {
        // TODO! Implement reusable content block
        return null;
      },
      campaignTabs: ({ value }) => {
        return (
          <div
            className={clsx('my-20', {
              'lg:-mx-16 xl:-mx-44': !forbidNegativeMargins,
            })}
          >
            <CampaignTabs
              title={value.title}
              subtitle={value.subtitle}
              tabs={value.tabs}
              activeSlug={activeCampaignSlug}
              NextLink={Link}
            />
          </div>
        );
      },
      campaignCardV3: ({ value }) => {
        const href =
          value.internalLink &&
          getPathFromInternalLink(value.internalLink, value);

        if (href) {
          return (
            <Link
              className={clsx('block my-20', {
                'lg:-mx-16 xl:-mx-32': !forbidNegativeMargins,
              })}
              href={href}
            >
              <CampaignCardV3 slots={value.slots} />
            </Link>
          );
        }

        return <CampaignCardV3 slots={value.slots} />;
      },
    },
  };

  return (
    <PortableText
      value={value as SanityBlock[]}
      components={portableTextComponents}
      onMissingComponent={(c) => console.error('missing', c)}
    />
  );
};
