import React from 'react';

type HighlightProps = {
  hit: any;
  attribute: string;
};

const highlightOpenTag = '<span class="cap-sm text-neutral-80">';
const highlightCloseTag = '</span>';

export default function Highlight({ hit, attribute }: HighlightProps) {
  if (!hit) {
    return <span />;
  }
  if (!hit._highlightResult) {
    return <span>{hit[attribute]}</span>;
  }
  const highlight = hit._highlightResult[attribute];

  const htmlValue = highlight.value
    .replaceAll('__ais-highlight__', highlightOpenTag)
    .replaceAll('__aa-highlight__', highlightOpenTag)
    .replaceAll('__/ais-highlight__', highlightCloseTag)
    .replaceAll('__/aa-highlight__', highlightCloseTag);

  return (
    <span
      className="body-sm text-neutral-70"
      dangerouslySetInnerHTML={{ __html: htmlValue }}
    />
  );
}
