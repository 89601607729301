import clsx from 'clsx';
import { AutoSizedText } from '@/components/CampaignCardV3/modules/AutoSizedText';
import { twMerge } from 'tailwind-merge';
import { getTextThemeStyles } from '@/components/CampaignCardV3/modules/Theme';

type CampaignTextProps = {
  slot?: string;
  module: {
    _type: 'campaignText';
    text?: string;
    theme: string;
    upperText?: string;
    _key: string;
  };
};

export const CampaignText = ({ module }: CampaignTextProps) => {
  return (
    <div
      className={twMerge(
        clsx(
          'w-full h-full flex flex-col gap-0 justify-center items-center text-white',
          getTextThemeStyles(module.theme),
          'bg-transparent'
        )
      )}
    >
      {module.upperText && (
        <span className="w-2/3 text-center h-3/4 max-h-5 sm:max-h-10 flex justify-center items-end">
          <AutoSizedText>{module.upperText}</AutoSizedText>
        </span>
      )}
      {module.text && (
        <h2 className="w-full text-center h-1/2 max-h-10 sm:max-h-32 flex justify-center items-start">
          <AutoSizedText>{module.text}</AutoSizedText>
        </h2>
      )}
    </div>
  );
};
