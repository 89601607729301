import { useNavigation } from '@/contexts/navigation';
import { useSearchContext } from '@/contexts/search';
import SearchHeading from './components/SearchHeading';
import { AutocompleteSourceName } from './components/SearchInput';
import GenericTextItem from './items/GenericTextItem';
import LinkItem from './items/LinkItem';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { useRouter } from 'next/router';

type AutocompleteModalProps = {
  className?: string;
};

const AutocompleteModal = ({ className = '' }: AutocompleteModalProps) => {
  const { searchMenu } = useNavigation();
  const router = useRouter();

  const {
    autocomplete,
    autocompleteState,
    setAutocompleteState,
    inputRef,
    removeRecentSearchItem,
  } = useSearchContext();

  const translatedHeading = (source: string) => {
    switch (source) {
      case 'main-search-recent':
        return 'Dine siste søk';
      case 'main-search-popular':
        return 'Populære søk';
      default:
        return source;
    }
  };

  const handleSelect = (item: any): void => {
    const prop = item.query ? 'query' : item.label ? 'label' : '';
    router.push(`/sok?q=${item[prop]}`);
  };

  const handleRemove = (id: string, sourceId: string): void => {
    if (sourceId === AutocompleteSourceName.RECENT_SEARCHES) {
      removeRecentSearchItem(id);
    }

    setAutocompleteState((prevState) => {
      const index = prevState.collections.findIndex(
        (collection) => collection.source.sourceId === sourceId
      );
      prevState.collections[index].items = prevState.collections[
        index
      ].items.filter((item) => item.id !== id);

      return { ...prevState };
    });
  };

  return (
    <div
      className={twMerge(
        clsx(
          'absolute bg-neutral-0 w-full lg:rounded-b-2xl text-black z-20 lg:shadow-md mt-4 lg:mt-0',
          className
        )
      )}
    >
      <div className="ml-5 mr-5 mb-5 pt-5 lg:border-t-1 border-t-neutral-50 flex flex-row gap-6">
        <div className="flex-grow">
          {autocompleteState.collections
            ?.filter(({ items }) => items.length > 0)
            .map(({ source, items }, index) => {
              return (
                <section key={index} className="mb-6">
                  <SearchHeading>
                    {translatedHeading(source.sourceId)}
                  </SearchHeading>
                  <ul
                    {...autocomplete.getListProps()}
                    className="flex flex-col gap-2 lg:gap-0"
                  >
                    {items.map((item, index) => {
                      return (
                        <GenericTextItem
                          key={index}
                          id={item.id}
                          sourceId={source.sourceId}
                          item={item}
                          prop={
                            item.query ? 'query' : item.label ? 'label' : ''
                          }
                          onSelect={handleSelect}
                          onRemove={handleRemove}
                          highlight={!!autocompleteState.query}
                        />
                      );
                    })}
                  </ul>
                </section>
              );
            })}
        </div>
        <div className="hidden lg:block w-80 min-h-full bg-secondary-30 rounded-lg p-6 box-border">
          <ul className="flex flex-col gap-2">
            {searchMenu?.map((item, index) => {
              return (
                <li key={index}>
                  <LinkItem item={item} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AutocompleteModal;
