import { Configure, Index } from 'react-instantsearch';
import ArticleResults from '../results/ArticleResuts';
import AutocompleteResults from '../results/AutocompleteResults';
import SerieResults from '../results/SerieResults';
import ProductResults from '../results/ProductResults';
import CategoryResults from '@/components/layout/Header/SmartSearch/results/CategoryResults';
import StoreResults from '@/components/layout/Header/SmartSearch/results/StoreResults';

const SearchResults = () => {
  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-[200px_2.1fr] gap-4">
      <div className="flex flex-col gap-2">
        <AutocompleteResults />
        <Index indexName="try_fagmobler_series">
          <Configure hitsPerPage={3} />
          <SerieResults />
        </Index>
        <Index indexName="stores">
          <Configure hitsPerPage={3} />
          <StoreResults />
        </Index>
        <Index indexName="categories">
          <Configure hitsPerPage={5} />
          <CategoryResults />
        </Index>
      </div>

      <div className="flex flex-col gap-2.5 border-t-1  pt-4 lg:pt-0 lg:border-t-0 lg:border-l-1 border-neutral-50 lg:pl-4">
        <ProductResults />
        <Index indexName="articles">
          <Configure hitsPerPage={4} />
          <ArticleResults />
        </Index>
      </div>
    </div>
  );
};

export default SearchResults;
