import clsx from 'clsx';
import { IconButton } from '@fagmobler/ui';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { AutoSizedText } from '@/components/CampaignCardV3/modules/AutoSizedText';

type CtaProps = {
  slot?: string;
  module: {
    _type: 'cta';
    text: string;
    _key: string;
  };
};

export const Cta = ({ module }: CtaProps) => {
  return (
    <div
      className={clsx(
        'flex items-start',
        'min-h-max',
        'justify-between',
        'items-center'
      )}
    >
      <h3 className="group-hover:underline text-white w-full">
        <AutoSizedText>{module.text}</AutoSizedText>
      </h3>

      <IconButton variant="ghost" size="lg" title={`Gå til`}>
        <ArrowRightIcon className="w-5 h-5" />
      </IconButton>
    </div>
  );
};
