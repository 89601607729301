import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { getThemeStyles } from '@/components/CampaignCardV3/modules/Theme';
import { AutoSizedText } from '@/components/CampaignCardV3/modules/AutoSizedText';

type DoubleBubbleProps = {
  slot?: string;
  module: {
    _type: 'bubble';
    _key: string;
    theme: string;
    smallTheme: string;
    upperText?: string;
    mainText: string;
    smallBubbleText: string;
  };
};

export const DoubleBubble = ({ module, slot }: DoubleBubbleProps) => {
  return (
    <div
      className={twMerge(
        clsx('w-full h-full flex items-center justify-center font-heading', {
          'items-end justify-start': slot === 'bottomLeft',
          'items-end justify-end': slot === 'bottomRight',
          'items-start justify-start': slot === 'topLeft',
          'items-start justify-end': slot === 'topRight',
        })
      )}
    >
      <span
        className={twMerge(
          clsx(
            'flex flex-col justify-center items-center h-full aspect-square rounded-full p-2 lg:p-3  @container relative',
            {
              'h-[50%] lg:h-[60%] p-4': slot === 'center',
            },
            getThemeStyles(module.theme, false),
            'rounded-full flex justify-center items-center'
          )
        )}
      >
        <span
          className={twMerge(
            clsx(
              '-tracking-normal p-1 absolute w-3/6 aspect-square -top-[10%] -right-[30%] lg:-top-[12%] lg:-right-[20%] rounded-full flex justify-center items-center text-xxxs',
              getThemeStyles(module.smallTheme, false)
            )
          )}
        >
          <AutoSizedText minFontSize={8}>
            {module.smallBubbleText}
          </AutoSizedText>
        </span>
        {module.upperText && (
          <span className={twMerge(clsx('block hd-base tracking-normal'))}>
            {module.upperText}
          </span>
        )}
        <AutoSizedText>{module.mainText}</AutoSizedText>
      </span>
    </div>
  );
};
