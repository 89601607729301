import clsx from 'clsx';
import React from 'react';
import { twMerge } from 'tailwind-merge';

type SearchHeadingProps = {
  className?: string;
  children: React.ReactNode;
};

export default function SearchHeading({
  children,
  className,
}: SearchHeadingProps) {
  return (
    <h4 className={twMerge(clsx('hd-base text-neutral-80 pb-2', className))}>
      {children}
    </h4>
  );
}
