import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type ThemeProps = {
  type?: string;
  module: {
    _type: 'theme';
    theme: string;
    _key: string;
  };
};

export const getTextThemeStyles = (theme: string) => {
  return {
    'text-neutral-40': theme === 'neutral',
    'text-neutral-80': theme === 'neutralDark' || theme === 'blackWeekBlack',
    'text-brown-30': theme === 'lightBrown',
    'text-tetiery-30': theme === 'lightYellow',
    'text-green-40': theme === 'lightGreen',
    'text-tetiery-60': theme === 'blackWeekYellow',
    'text-brown-70': theme === 'nobel' || theme === 'garden',
    'text-primary-50': theme === 'red',
  };
};

export const getThemeStyles = (theme: string, onlyBackground: boolean) => {
  if (onlyBackground) {
    return {
      'bg-neutral-40': theme === 'neutral',
      'bg-neutral-80': theme === 'neutralDark' || theme === 'blackWeekBlack',
      'bg-brown-30': theme === 'lightBrown',
      'bg-tetiery-30': theme === 'lightYellow',
      'bg-green-40': theme === 'lightGreen',
      'bg-tetiery-60': theme === 'blackWeekYellow',
      'bg-brown-70': theme === 'nobel' || theme === 'garden',
      'bg-primary-50': theme === 'red',
    };
  }

  return {
    'bg-neutral-40 text-neutral-80': theme === 'neutral',
    'text-neutral-0 bg-neutral-80': theme === 'neutralDark',
    'bg-brown-30 text-neutral-80': theme === 'lightBrown',
    'bg-tetiery-30 text-neutral-80': theme === 'lightYellow',
    'bg-green-40 text-neutral-80': theme === 'lightGreen',
    'bg-tetiery-60 text-neutral-80': theme === 'blackWeekYellow',
    'bg-neutral-80 text-tetiery-50': theme === 'blackWeekBlack',
    'bg-brown-70 text-gold-40': theme === 'nobel',
    'bg-primary-50 text-neutral-0': theme === 'red',
    'bg-brown-70 text-pink-60': theme === 'garden',
  };
};

export const Theme = (props: ThemeProps) => {
  const theme = props.module.theme;
  return (
    <div
      className={twMerge(clsx('w-full h-full', getThemeStyles(theme, true)))}
    ></div>
  );
};
