import useOnClickOutside from '@/hooks/click-outside';
import useWindowDimensions from '@/hooks/window-dimensions';
import { searchClient } from '@/lib/algolia/searchClient';
import {
  AutocompleteApi,
  AutocompleteState,
  createAutocomplete,
} from '@algolia/autocomplete-core';
import { AutocompletePlugin } from '@algolia/autocomplete-js';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';
import { Hit } from 'instantsearch.js';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import {
  BaseSyntheticEvent,
  createContext,
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';

export const MOBILE_BREAKPOINT = 1024;

export type AutocompleteItem = Hit<{
  __autocomplete_id?: string | number;
  id: string;
  query?: string;
  label?: string;
  objectID: string;
}>;

type SearchContextProps = {
  autocomplete: AutocompleteApi<
    AutocompleteItem,
    BaseSyntheticEvent,
    MouseEvent
  >;
  autocompleteState: AutocompleteState<AutocompleteItem>;
  setAutocompleteState: Dispatch<
    SetStateAction<AutocompleteState<AutocompleteItem>>
  >;
  recentSearchItems: string[];
  removeRecentSearchItem: (id: string) => void;
  showResults: boolean;
  setShowResults: (show: boolean) => void;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  headerRef: MutableRefObject<HTMLElement | null>;
  wrapperRef: MutableRefObject<HTMLDivElement | null>;
  closeSearch: () => void;
};

type SearchContextProviderProps = {
  children: ReactNode;
};

export const SearchContext = createContext<SearchContextProps>(
  {} as SearchContextProps
);

export const useSearchContext = (): SearchContextProps => {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error('useSearchContext must be within StateProvider');
  }
  return context;
};

const SearchContextProvider = ({
  children,
}: SearchContextProviderProps): JSX.Element => {
  const router = useRouter();
  const [autocompleteState, setAutocompleteState] = useState<
    AutocompleteState<AutocompleteItem>
  >({} as AutocompleteState<AutocompleteItem>);
  const [showResults, setShowResults] = useState<boolean>(false);
  const { width } = useWindowDimensions();

  const inputRef = useRef<HTMLInputElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { indexUiState, setIndexUiState } = useInstantSearch();
  const { query } = useSearchBox();
  const searchParams = useSearchParams();

  const closeSearch = () => {
    if (autocompleteState.isOpen) {
      setAutocompleteState((prevState) => {
        return { ...prevState, isOpen: false };
      });
      autocomplete.setIsOpen(false);
    }
    if (showResults) {
      setShowResults(false);
    }
  };

  useOnClickOutside(wrapperRef, () => {
    if (width >= MOBILE_BREAKPOINT) {
      closeSearch();
    }
  });

  const recentSearches = useMemo(() => {
    return createLocalStorageRecentSearchesPlugin({
      key: 'instant-search',
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'main-search-recent',
          onSelect: ({ item }): void => {
            setIndexUiState((prevState) => {
              return { ...prevState, query: item.label };
            });
          },
        };
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const recentSearchItems = useMemo(() => {
    return (
      recentSearches.data?.getAll() as { id: string; label: string }[]
    ).map((suggestion) => suggestion.label);
  }, [recentSearches]);

  // eslint-disable-next-line
  const plugins: AutocompletePlugin<any, any>[] = useMemo(() => {
    const querySuggestions = createQuerySuggestionsPlugin({
      searchClient: searchClient,
      indexName: 'try_fagmobler_query_suggestions',
      transformSource({ source }) {
        return {
          ...source,
          sourceId: 'main-search-popular',
          onSelect: ({ item }): void => autocomplete.setQuery(item.query),
        };
      },
    });

    return [recentSearches, querySuggestions];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const autocomplete = useMemo(
    () =>
      createAutocomplete<
        AutocompleteItem,
        BaseSyntheticEvent,
        MouseEvent,
        KeyboardEvent
      >({
        openOnFocus: true,
        plugins,
        onSubmit: ({ state }) => {
          router.push(`/sok?q=${state.query}`);
        },
        onReset: () => setIndexUiState({ query: '' }),
        onStateChange: ({ state }) => {
          setIndexUiState({ query: state.query });
          setAutocompleteState(state);
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Set query from URL
  useEffect(() => {
    const query = searchParams.get('q');
    if (query) {
      autocomplete.setQuery(query);
      setIndexUiState({ query: query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // Show results when query is more than 2 characters
  useEffect(
    () =>
      setShowResults(
        !!indexUiState.query &&
          indexUiState.query.length > 2 &&
          document.activeElement === inputRef.current
      ),
    [
      indexUiState.query,
      autocompleteState.isOpen,
      document.activeElement,
      inputRef.current,
    ]
  );

  // Close autocomplete when results are shown
  useEffect(() => {
    if (
      showResults &&
      autocompleteState.isOpen &&
      !!query &&
      query.length > 2
    ) {
      autocomplete.setIsOpen(false);
    }
  }, [query, showResults, autocompleteState, autocomplete]);

  // Close search when navigating to another page
  useEffect(() => {
    closeSearch();
    if (router.pathname !== '/sok' && router.pathname !== '/artikkelsok') {
      autocomplete.setQuery('');
      setIndexUiState({ query: '' });
      setShowResults(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const removeRecentSearchItem = (id: string): void => {
    if (!recentSearches || !recentSearches.data) {
      return;
    }

    recentSearches.data.removeItem(id);
  };

  return (
    <SearchContext.Provider
      value={{
        autocomplete,
        autocompleteState,
        setAutocompleteState,
        recentSearchItems,
        removeRecentSearchItem,
        showResults,
        setShowResults,
        inputRef,
        headerRef,
        wrapperRef,
        closeSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
