import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type LogoProps = {
  slot?: string;
  module: {
    design: string;
    _key: string;
    _type: 'logo';
  };
};

const designs = {
  blackWeekLight: '/gfx/blackweek-logo-light.svg',
  blackWeekDark: '/gfx/blackweek-logo-dark.svg',
  saleSaleSale: '/gfx/salgsalgsalg.svg',
};

const getDesign = (design: string) => {
  return designs[design as keyof typeof designs] || '';
};

export const Logo = ({ slot, module }: LogoProps) => {
  return (
    <div
      className={twMerge(
        clsx('relative w-full h-full', {
          'flex items-center justify-center': slot === 'center',
        })
      )}
    >
      <div
        className={twMerge(
          clsx('aspect-square', {
            'w-full max-w-80': slot === 'center',
            'h-full': slot !== 'center',
          })
        )}
        style={{
          backgroundImage: `url(${getDesign(module.design)})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent',
        }}
      ></div>
    </div>
  );
};
