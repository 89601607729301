import { TagIcon } from '@heroicons/react/24/outline';
import { useInstantSearch } from 'react-instantsearch';
import GenericTextItem from '../items/GenericTextItem';
import SearchHeading from '../components/SearchHeading';
import { useRouter } from 'next/router';

const SerieResults = () => {
  const { results } = useInstantSearch();
  const router = useRouter();

  if (results.hits.length === 0) {
    return <></>;
  }

  const handleSelect = (hit: any): void => {
    router.push(`/serie/${hit.slug}`);
  };

  return (
    <section>
      <ul className="flex flex-col gap-2">
        {results.hits.map((hit, index) => (
          <li key={index}>
            <GenericTextItem
              key={index}
              id={hit.id}
              sourceId={hit.sourceId}
              item={hit}
              prop="title"
              highlight={false}
              onSelect={handleSelect}
              icon="tag"
              onRemove={() => {}}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default SerieResults;
