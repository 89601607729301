import { configuredSanityClient } from '@/lib/sanity/sanityClient';
import { SanityImageAsset, SanityReference } from '@fagmobler/sanity';
import { SanityClient } from '@sanity/client';

export const getSanityImageUrl = async (
  image: {
    _type?: 'image';
    asset?: SanityReference<SanityImageAsset>;
  },
  client: SanityClient = configuredSanityClient
) => {
  if (image._type === 'image' && image.asset?._ref) {
    const imageUrlBuilder = await import('@sanity/image-url');
    const urlBuilder = imageUrlBuilder.default(client);

    return urlBuilder.image(image).url();
  }

  return null;
};
