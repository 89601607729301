import { useInstantSearch } from 'react-instantsearch';
import ArticleItem from '../items/ArticleItem';
import Link from 'next/link';
import SearchHeading from '../components/SearchHeading';
import { useSearchContext } from '@/contexts/search';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { Button } from '@fagmobler/ui';

const ArticleResults = () => {
  const { results } = useInstantSearch();
  const { autocompleteState } = useSearchContext();

  if (results.hits.length === 0) {
    return <></>;
  }

  return (
    <section className="flex flex-col gap-2">
      <div className="flex justify-between items-center lg:ml-2">
        <SearchHeading className="p-0">Artikler</SearchHeading>
        {results.nbHits > 0 && (
          <Link
            href={`/artikkelsok?q=${autocompleteState.query}`}
            className="cap-link-sm text-neutral-80"
            tabIndex={-1}
          >
            <Button
              variant="outline"
              className="!rounded-[100px] hover:bg-neutral-80 hover:text-neutral-20"
              size="xs"
            >
              Vis alle {results.nbHits} treff
            </Button>
          </Link>
        )}
      </div>
      <ul className="grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-0">
        {results.hits.map((hit, index) => (
          <li
            key={index}
            className={twMerge(
              clsx('hover:bg-neutral-30 rounded lg:p-2 w-full', {
                'lg:hidden': index > 2,
              })
            )}
          >
            <ArticleItem hit={hit} />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ArticleResults;
