import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';
import SearchResults from './components/SearchResults';

type ResultsModalProps = {
  className?: string;
};

const ResultsModal = ({ className = '' }: ResultsModalProps) => {
  return (
    <div
      className={twMerge(
        clsx(
          'absolute bg-neutral-0 w-full lg:rounded-b-2xl text-black z-20 lg:shadow-md mt-4 lg:mt-0',
          className
        )
      )}
    >
      <div className="lg:mx-5 mb-5 pt-5 lg:border-t-1 border-t-neutral-50 flex flex-row gap-6">
        {/* {hits.length > 0 ? <NoSearchResults /> : <SearchResults />} */}
        <SearchResults />
      </div>
    </div>
  );
};

export default ResultsModal;
