import { useSearchContext } from '@/contexts/search';
import React from 'react';
import GenericTextItem from '../items/GenericTextItem';
import { AutocompleteSourceName } from '../components/SearchInput';
import SearchHeading from '../components/SearchHeading';
import { useRouter } from 'next/router';

const AutocompleteResults = () => {
  const { autocompleteState, autocomplete, inputRef } = useSearchContext();
  const router = useRouter();

  const popularSearches = autocompleteState.collections?.filter(
    ({ source, items }) =>
      items.length > 0 &&
      source.sourceId === AutocompleteSourceName.POPULAR_SEARCHES
  );

  const handleSelect = (item: any): void => {
    const prop = item.query ? 'query' : item.label ? 'label' : '';
    router.push(`/sok?q=${item[prop]}`);
  };

  if (!popularSearches?.some(({ items }) => items.length > 0)) {
    return <></>;
  }

  const handleRemove = (id: string, sourceId: string) => {};

  return (
    <section>
      <div className="flex justify-between lg:mx-2">
        <SearchHeading>Forslag</SearchHeading>
      </div>
      {popularSearches.map(({ source, items }, index) => {
        return (
          <ul
            key={index}
            {...autocomplete.getListProps()}
            className="flex flex-col gap-2 lg:gap-0"
          >
            {items.slice(0, 3).map((item, index) => {
              return (
                <GenericTextItem
                  key={index}
                  id={item.id}
                  sourceId={source.sourceId}
                  item={item}
                  prop={item.query ? 'query' : item.label ? 'label' : ''}
                  highlight={true}
                  onSelect={handleSelect}
                  onRemove={handleRemove}
                />
              );
            })}
          </ul>
        );
      })}
    </section>
  );
};

export default AutocompleteResults;
