import { useSearchContext } from '@/contexts/search';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import Image from 'next/image';
import { Button } from '@fagmobler/ui';

export enum AutocompleteSourceName {
  RECENT_SEARCHES = 'main-search-recent',
  POPULAR_SEARCHES = 'main-search-popular',
}

const SearchInput = () => {
  const {
    autocomplete,
    inputRef,
    closeSearch,
    autocompleteState,
    showResults,
  } = useSearchContext();

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 1024;

  // eslint-disable-next-line
  const attributes: any = {
    ...autocomplete.getInputProps({
      inputElement: inputRef.current,
    }),
  };

  return (
    <div className="h-11 w-full px-5" {...autocomplete.getRootProps({})}>
      <form
        className="h-full w-full flex gap-2 align-middle justify-center"
        {...autocomplete.getFormProps({ inputElement: inputRef.current })}
      >
        {!attributes.value.length && (
          <button
            className={clsx('h-full', {
              'hidden lg:block': autocompleteState.isOpen,
            })}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              inputRef.current?.focus();
            }}
          >
            <Image
              src="/gfx/icons/search.svg"
              width={20}
              height={20}
              unoptimized={true}
              alt="Search"
              className="w-5 h-5"
            />
          </button>
        )}
        {(autocompleteState.isOpen || showResults) && (
          <button
            className="h-full lg:hidden"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              inputRef.current?.blur();
              closeSearch();
            }}
          >
            <Image
              src="/gfx/icons/arrow-left.svg"
              width={20}
              height={20}
              unoptimized={true}
              alt="Close Search"
              className="w-5 h-5"
            />
          </button>
        )}
        <input
          {...attributes}
          ref={inputRef}
          type="search"
          placeholder={
            isMobile
              ? 'Søk etter produkter'
              : 'Søk etter produkter, kategorier, varemerker...'
          }
          name="autocomplete"
          className="flex-grow indent-2 text-black outline-none bg-white"
        />
        {attributes.value.length > 0 && (
          <div className="flex gap-1 h-full">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                autocomplete.setQuery('');
                inputRef.current?.focus();
              }}
              className="h-full"
            >
              <Image
                src="/gfx/icons/close.svg"
                width={20}
                height={20}
                unoptimized={true}
                alt="Clear Input"
                className="w-5 h-5"
              />
            </button>
            <div className="h-5 self-center" />
            <Button
              type="submit"
              size="xs"
              className="hidden lg:block h-4/5 !rounded-full self-center px-4"
            >
              Søk
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default SearchInput;
