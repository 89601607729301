import Link from 'next/link';
import React from 'react';

type CategoryItemProps = {
  hit: any;
};

export default function CategoryItem({ hit }: CategoryItemProps) {
  const parseSlug = (slug: string) => {
    const parts = slug.split(' > ');

    return parts.join('/');
  };

  return (
    <Link
      href={`/rom/${parseSlug(hit.slug)}`}
      className="rounded-full px-2 py-1.5 bg-secondary-30 body-xs text-neutral-80 hover:bg-secondary-40"
    >
      {hit.title}
    </Link>
  );
}
